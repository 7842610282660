import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import FatturaPilota from './FatturaPilota'; // Importa il componente FatturaPilota

const FatturePerTipo = ({ tipo, fatture, anno }) => {
  // Raggruppa le fatture per idfatturapilota
  const fattureRaggruppate = fatture.reduce((acc, fattura) => {
    if (!acc[fattura.idfatturapilota]) {
      acc[fattura.idfatturapilota] = [];
    }
    acc[fattura.idfatturapilota].push(fattura);
    return acc;
  }, {});

  return (
    <Box key={tipo} mt={2}>
      <Typography variant="subtitle1">Tipo Documento: {tipo} {anno ? `(${anno})` : ''}</Typography>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '10px' }}>
        {Object.values(fattureRaggruppate).map((fatture) => (
          <FatturaPilota key={fatture[0].idfatturapilota} fatture={fatture} />
        ))}
      </Paper>
    </Box>
  );
};

export default FatturePerTipo;