import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const FatturaDettaglio = ({ fattura }) => {
  const euroFormatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });

  // Calcola imponibile per quantità
  const imponibilePerQuantita = fattura.imponibile * fattura.quantita;

  return (
    <Box mt={0} p={2} border="1px solid #ccc">
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography style={{ fontSize: '0.8rem' }}>{fattura.descrizione_fattura}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ fontSize: '0.8rem', textAlign: 'right' }}>{fattura.quantita}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ fontSize: '0.8rem', textAlign: 'right' }}>
            {euroFormatter.format(fattura.imponibile)}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ fontSize: '0.8rem', textAlign: 'right' }}>
            {euroFormatter.format(imponibilePerQuantita)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FatturaDettaglio;