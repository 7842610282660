import React, { useState } from 'react';
import {
  Box,
  Typography,
  Chip,
  IconButton,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FatturaDettaglio from './FatturaDettaglio';
import moment from 'moment';

const FatturaPilota = ({ fatture }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fatturaDaPagare, setFatturaDaPagare] = useState(null);
  const [dataPagamento, setDataPagamento] = useState('');

  if (!fatture || fatture.length === 0) {
    return <Typography>Nessuna fattura trovata.</Typography>;
  }

  // Calcola la somma di "imponibile"
  const sommaImponibile = fatture.reduce((acc, fattura) => {
    const imponibile = parseFloat(fattura['imponibile'] * fattura['quantita']);
    return acc + (isNaN(imponibile) ? 0 : imponibile);
  }, 0);

  // Assumiamo che tutte le fatture nell'array abbiano lo stesso idfatturapilota e nomecliente
  const primaFattura = fatture[0];

  const euroFormatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });

  const handlePagaFatturaClick = (fattura) => {
    setFatturaDaPagare(fattura);
    setOpenDialog(true);
  };

  const handleConfermaPagamento = async () => {
    try {
      // Sostituisci 'YOUR_API_ENDPOINT' con l'URL corretto della tua API.
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/invoices/pagamenti/pagaFatturaPilota/${fatturaDaPagare.idfatturapilota}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Aggiungi il token se necessario
          },
          body: JSON.stringify({ dataPagamento }), // Invia la data di pagamento
        }
      );

      if (!response.ok) {
        throw new Error(`Errore HTTP! status: ${response.status}`);
      }
      console.log('pagamento eseguito con successo');
      // Qui puoi aggiungere la logica per aggiornare l'UI dopo il pagamento.
    } catch (error) {
      console.error('Si è verificato un errore:', error);
      // Qui puoi aggiungere la gestione degli errori.
    } finally {
      setOpenDialog(false);
      setFatturaDaPagare(null);
      setDataPagamento('');
    }
  };

  // Funzione per formattare la data
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return moment(dateString).format('DD/MM/YYYY');
  };

  return (
    <Card style={{ marginBottom: '16px' }}>
      <CardContent
        onClick={() => setShowDetails(!showDetails)}
        style={{ cursor: 'pointer', backgroundColor: primaFattura.datapag ? 'limegreen' : 'white', color: primaFattura.datapag ? 'white' : 'black' }}
      >
        <Box display="flex" alignItems="center">
          <Chip label={primaFattura.numerofattura_pilota} color="primary" style={{ marginRight: '16px' }} />
          <Typography style={{ marginRight: '16px' }}>{primaFattura.nomecliente}</Typography>

          <Typography style={{ marginLeft: 'auto', fontWeight: 'bold' }}>
            {primaFattura.datapag ? formatDate(primaFattura.datapag) : ''}
          </Typography>

          <Typography style={{ marginLeft: 'auto', fontWeight: 'bold' }}>
            {euroFormatter.format(sommaImponibile)}
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setShowDetails(!showDetails);
            }}
          >
            {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          {!primaFattura.datapag && ( // Nascondi l'icona se la fattura è pagata
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handlePagaFatturaClick(primaFattura);
              }}
            >
              <PaymentIcon />
            </IconButton>
          )}
        </Box>
        {showDetails &&
          fatture.map((fattura) => (
            <FatturaDettaglio key={fattura.idfattura} fattura={fattura} />
          ))}
      </CardContent>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Conferma Pagamento</DialogTitle>
        <DialogContent>
          <DialogContentText>Sei sicuro di voler contrassegnare la fattura come pagata?</DialogContentText>
          <TextField
            label="Data di Pagamento"
            type="date"
            value={dataPagamento}
            onChange={(e) => setDataPagamento(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Annulla
          </Button>
          <Button
            onClick={handleConfermaPagamento}
            disabled={!dataPagamento}
            style={{ backgroundColor: dataPagamento ? 'limegreen' : null, color: dataPagamento ? 'white' : null }}
            autoFocus
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default FatturaPilota;