import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Avatar, TextField, Button, Divider, CircularProgress } from '@mui/material'; // Import CircularProgress e Divider
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ShieldIcon from '@mui/icons-material/Security';
import theme from '../theme';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'; // Importa i componenti necessari
import { jwtDecode } from 'jwt-decode';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false); // Stato per il caricamento del login standard
    const [googleLoading, setGoogleLoading] = useState(false); // Stato per il caricamento di Google
    const [error, setError] = useState('');  // Stato per gli errori

    const navigate = useNavigate();


    const handleGoogleLogin = async (credentialResponse) => {
        setGoogleLoading(true); // Imposta il caricamento di Google
        setError(''); // Pulisci gli errori precedenti
        try {
            const userObject = jwtDecode(credentialResponse.credential);

            const backendResponse = await fetch('https://www.paoloniinformatica.it/papi/auth/google-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: userObject.email,
                    nome: userObject.given_name,
                    cognome: userObject.family_name,
                    database:"AP",
                    googleId: userObject.sub,
                    profilePicture: userObject.picture
                }),
            });

            if (backendResponse.ok) {
                const data = await backendResponse.json();
                localStorage.setItem('token', data.token);
                localStorage.setItem('nome', data.nome);
                localStorage.setItem('cognome', data.cognome);
                localStorage.setItem('livello', data.livello);
                localStorage.setItem('profilePicture', data.profilePicture); // Assicurati che il backend lo invii
                navigate('/dashboard');
            } else {
                const errorData = await backendResponse.json(); // Prendi i dati dell'errore
                setError(errorData.message || 'Errore durante l\'autenticazione con Google.'); // Imposta l'errore
            }
        } catch (error) {
            console.error("Errore durante l'autenticazione con Google:", error);
             setError('Errore di comunicazione con il server.'); // Imposta un messaggio di errore generico

        } finally {
            setGoogleLoading(false); // Disattiva il caricamento di Google
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true); // Imposta il caricamento standard
        setError(''); // Pulisci errori precedenti
        try {
            const response = await fetch('https://www.paoloniinformatica.it/papi/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password, database:"AP" }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token);
                localStorage.setItem('nome', data.nome);
                localStorage.setItem('cognome', data.cognome);
                localStorage.setItem('livello', data.livello);
                // localStorage.setItem('profilePicture', data.profilePicture); // Non presente nel login standard
                navigate('/dashboard');
            } else {
                 const errorData = await response.json(); // Prendi i dati dell'errore
                 setError(errorData.message || 'Login failed'); // Imposta lo stato di errore
            }
        } catch (error) {
            console.error("Errore durante il login:", error);
             setError('Errore di comunicazione con il server.'); // Imposta un messaggio di errore generico
        } finally {
            setLoading(false); // Disattiva il caricamento standard
        }
    };


    return (
      <GoogleOAuthProvider clientId="1075635104017-1f5e19tao7o1nqdq0jvi91088qf6kmju.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Card sx={{ width: 300, padding: 2 }}>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Avatar sx={{ bgcolor: theme.palette.primary.main, mb: 2 }}>
                                <ShieldIcon />
                            </Avatar>
                            <Typography variant="h4" color="primary" gutterBottom>
                                Login Paladino
                            </Typography>

                            {/* Visualizzazione degli errori */}
                            {error && (
                                <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                                    {error}
                                </Typography>
                            )}

                            <form onSubmit={handleLogin} style={{ width: '100%' }}>
                                <TextField
                                    label="Username"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    InputLabelProps={{ style: { color: theme.palette.primary.main } }}
                                />
                                <TextField
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputLabelProps={{ style: { color: theme.palette.primary.main } }}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    disabled={loading} // Disabilita durante il caricamento
                                    sx={{
                                        marginTop: '1rem',
                                        backgroundColor: theme.palette.secondary.main,
                                        color: theme.palette.secondary.contrastText,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.dark,
                                        },
                                    }}
                                >
                                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'} {/* Indicatore di caricamento */}
                                </Button>
                            </form>

                            <Divider sx={{ my: 2, width: '100%' }} /> {/* Linea divisoria */}

                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                {/* Pulsante Google */}
                                <GoogleLogin
                                    onSuccess={handleGoogleLogin}
                                    onError={() => {
                                        console.error('Login Failed');
                                        setError('Errore durante il login con Google.'); // Imposta l'errore
                                    }}
                                    useOneTap  // <--- prova a vedere se funziona.
                                />
                                 {googleLoading && <CircularProgress size={24} sx={{ position: 'absolute' }} />} {/* Indicatore di caricamento */}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </ThemeProvider>
      </GoogleOAuthProvider>
    );
};

export default Login;