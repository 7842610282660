import React, { useEffect, useState } from 'react';
import { Grid, Tabs, Tab, Box } from '@mui/material';
import Layout from './Layout';
import FatturePerTipo from './FatturePerTipo'; // Importa il componente FatturePerTipo

const PagamentiFatture = () => {
  const [fatture, setFatture] = useState([]);
  const [selectedYear, setSelectedYear] = useState(0); // Inizializza al primo anno
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchFatture = async () => {
      try {
        const response = await fetch(`${apiUrl}/invoices/getPagamentiFatture`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFatture(data);
      } catch (error) {
        console.error('Errore nel recupero dei dati delle fatture:', error);
      }
    };
    fetchFatture();
  }, [apiUrl, token]);

  const handleYearChange = (event, newValue) => {
    setSelectedYear(newValue);
  };

  const groupAndSortFatture = (fatture) => {
    const fatturePerAnno = fatture.reduce((acc, fattura) => {
      const anno = fattura.annofattura_pilota;
      if (!acc[anno]) {
        acc[anno] = [];
      }
      acc[anno].push(fattura);
      return acc;
    }, {});

    const sortedAnni = Object.keys(fatturePerAnno).sort((a, b) => b - a);

    return sortedAnni.map((anno) => {
      const fatturePerTipo = fatturePerAnno[anno].reduce((acc, fattura) => {
        const tipo = fattura.tipo_pilota;
        if (!acc[tipo]) {
          acc[tipo] = [];
        }
        acc[tipo].push(fattura);
        return acc;
      }, {});

      const sortedTipi = Object.keys(fatturePerTipo).sort();
      const fatturePerTipoOrdinate = sortedTipi.map(tipo => {
        return {tipo, fatture: fatturePerTipo[tipo].sort((a,b) => a.numerodocumento - b.numerodocumento)}
      })
      return { anno, fatturePerTipo: fatturePerTipoOrdinate };
    });
  };

  const groupedFatture = groupAndSortFatture(fatture);

  return (
    <Layout>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Tabs value={selectedYear} onChange={handleYearChange} aria-label="years-tabs">
            {groupedFatture.map(({ anno }, index) => (
              <Tab key={anno} label={anno} />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {groupedFatture.length > 0 && groupedFatture[selectedYear].fatturePerTipo.map(({ tipo, fatture: fattureTipo }) => (
            <FatturePerTipo
              key={tipo}
              tipo={tipo}
              fatture={fattureTipo}
              anno={groupedFatture[selectedYear].anno} // Passa l'anno come prop
            />
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PagamentiFatture;