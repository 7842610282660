import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Progetti from './Progetti';  // Importa il nuovo componente Progetto

const SchedaCard = () => {
    const [schede, setSchede] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_API_URL; // Usa la variabile d'ambiente

        fetch(`${apiUrl}/projects/getSchede`, { // Usa apiUrl
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => setSchede(data))
            .catch((error) => console.error('Errore nel recupero dei dati:', error));
    }, []);

    return (
        <Grid container spacing={3} justifyContent="center">
            {schede.map((scheda, index) => (
                <Grid item key={index} xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{ backgroundColor: '#d9f9d9' }} // Aggiunge uno sfondo chiaro
                        >
                            <Grid container spacing={2}>
                                {/* Numero Scheda */}
                                <Grid item xs={12} md={2}>
                                    <Paper elevation={3} style={{ padding: '10px', backgroundColor: '#ffffff' }}>
                                        <Box display="flex" alignItems="center">
                                            <AssignmentIcon color="primary" sx={{ mr: 2 }} />
                                            <Box>
                                                <Typography variant="body2" color="text.info">
                                                    Numero Scheda
                                                </Typography>
                                                <Typography variant="h6" color="primary" fontWeight="bold">
                                                    {scheda.numeroscheda}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>

                                {/* Contratto */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} style={{ padding: '10px', backgroundColor: '#ffffff' }}>
                                        <Box display="flex" alignItems="center">
                                            <DescriptionIcon color="secondary" sx={{ mr: 2 }} />
                                            <Box>
                                                <Typography variant="body2" color="text.info">
                                                    Contratto
                                                </Typography>
                                                <Typography variant="h6" color="secondary" fontWeight="bold">
                                                    {scheda.oggetto_contratto}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>

                                {/* Sito */}
                                <Grid item xs={12} md={4}>
                                    <Paper elevation={3} style={{ padding: '10px', backgroundColor: '#ffffff' }}>
                                        <Box display="flex" alignItems="center">
                                            <LocationOnIcon color="error" sx={{ mr: 2 }} />
                                            <Box>
                                                <Typography variant="body2" color="text.info">
                                                    Sito
                                                </Typography>
                                                <Typography variant="h6" color="error" fontWeight="bold">
                                                    {scheda.nomesito}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        
                            {/* Componente Progetto */}
                            <Progetti idScheda={scheda.idscheda} />
                        
                    </Accordion>
                </Grid>
            ))}
        </Grid>
    );
};

export default SchedaCard;
